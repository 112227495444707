import React from 'react'
import "normalize.css"
import Layout from './components/layout'
import Head from '../pages/components/head'

const ContactPage = () => {
    return(
        <Layout>
            <Head title="Contact" />
            <h1>Contact</h1>
            <p>The best way to reach me is via <a href="mailto:loanfigueiredo@gmail.com" target="_blank" rel="noopener noreferrer">Gmail</a> </p>
        </Layout>
    )
}

export default ContactPage